@font-face {
  font-family: "akaya";
  src: local("akaya"), url("./fonts/AkayaTelivigala-Regular.ttf") format("ttf");
}

* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  color: #6e0576;
}
body {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: rgb(240, 238, 238);
  overflow-x: hidden;
  max-width: 100vw;
}
h3 {
  font-size: 40px;
  /* font-family: ; */
}
li {
  /* color: red; */
  font-size: 28px;
}
p {
  font-size: 23px;
}
.whatWeDo {
  display: flex;
  flex-wrap: wrap;
  max-width: 100vw;
  min-width: 100vw;
  /* border: 2px solid blue; */
}
.container {
  display: flex;
  width: 100%;
  /* background-image: url(/src/images/bgh.jpg); */
  /* background-size: cover;
  background-repeat: no-repeat; */
  position: fixed;
  top: 0;
}
.containerH {
  display: flex;
  width: 100%;
  /* background-image: url(/src/images/bgh.jpg); */
  /* background-size: cover;
  background-repeat: no-repeat; */
}
.logo {
  width: 40px;
  height: 40px;
  margin-left: 40px;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: fixed;
  background-color: #eeea;
  max-height: 35px;
  min-height: 30px;
  box-shadow: 1px 1px 10px 0px #c2cfd8;
  overflow: hidden;
}
.headerRest {
  margin-right: 40px;
  display: flex;
  width: 60%;
  justify-content: space-between;
  align-items: center;
  margin-right: 40;
  font-size: 18px;
  font-family: cursive;
}
.headerRest a {
  color: rgb(95, 5, 105);
  font-size: 18px;
}
.bgImages {
  display: flex;
  /* flex: 1; */
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  background-image: url("../public/bgh.jpg");
  background-repeat: no-repeat;
  background-color: #e7d76f;
  height: 100%;
  min-height: 100vh;
  max-height: 100vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  overflow: hidden;
}
.homedp {
  display: flex;
  height: 90vh;
  width: 100%;
}
.homedptDiv {
  display: flex;
  /* border: 5px solid red; */
  /* flex: 0; */
  width: 55vw;
  height: 50vw;
  /* flex-shrink: 1; */
}
.homedpt {
  display: flex;
  width: 100%;
  height: 100%;

  /* right: -500px; */
}
.bgText {
  font-size: 3.3rem;
  /* width: 70%; */
  /* margin: 20px 20px 10px 70px; */
  text-align: center;
  margin-right: 30px;
  /* font-family: akaya; */
  text-align: justify;
}
.bgText2 {
  font-size: 1.7rem;
  /* width: 70%; */
  /* margin: 0 20px 10px 70px; */
  margin-right: 30px;
  text-align: center;
  color: #000000;

  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.topContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.topFull {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
}
.topInner {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px;
  justify-content: space-evenly;
  align-items: center;
}
.topLast {
  display: flex;
  flex-direction: column;
  /* width: 60%; */
  justify-content: center;
  align-items: center;
}
.icon {
  width: 10rem;
  height: 10rem;
  border-radius: 100px;
  background-color: white;
  margin: 10px;
  margin-bottom: 20px;
  box-shadow: 2px 2px 10px;
  padding: 15px;
}
.text {
  margin: 3px;
  /* padding: 0 10px; */
  text-align: justify;
  /* border: 2px solid black; */
  font-size: 1.3rem;
  text-align: center;
}
/* h3 {
  padding: 10px;
  /* width: 50%; */
/* } */
.topic {
  color: #000000;
  font-weight: 800;
  font-size: 1.2em;
}
.cover {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* padding: 30px; */
  margin: 50px 10px;
  justify-content: space-between;
  overflow-x: scroll;
  overflow-y: hidden;
}
.cust {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.custCover {
  display: flex;
  flex-direction: row;
  margin: 50px 10px;
  justify-content: space-between;
  overflow: hidden;
}
.custImage {
  height: 250px;
  width: 350px;
  /* border: 1px solid ; */
  box-shadow: 6px 6px 15px rgb(103, 103, 104);
  border-radius: 20px;
}
.serve {
  display: flex;
  flex-direction: row;
  /* flex-grow: initial; */
  /* border: 1px solid black; */
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 30px 10px;
}
.serviceImageDiv {
  display: flex;
  /* height: 100%; */
  /* width: 100%; */
}
.serviceImage {
  height: 250px;
  width: 250px;
  box-shadow: 6px 6px 15px rgb(103, 103, 104);
  border-radius: 20px;
  margin-right: 5px;
}
.content {
  display: flex;
  /* width: 100%; */
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: justify;
  padding: 10px;
  margin: 10px;
  /* border: 1px solid blue; */
}
.serviceMain {
  padding: 20px;
  background-color: #fff;
  /* margin: 20px; */
  border-radius: 20px;
  box-shadow: 1px 1px 10px rgb(200, 200, 212);
  /* overflow-x: scroll;
  overflow-y: hidden; */
}
.title {
  width: 100%;
  font-size: 22px;
  font-weight: 600;
}
.custTitle {
  /* width: 100%; */
  font-size: 28px;
  font-weight: 600;
}
.custContent {
  display: flex;
  width: 300px;
  flex-direction: column;
  /* text-align: justify; */
  padding: 10px 40px;
  justify-content: center;
  align-items: center;
}
.cust {
  align-items: center;
  justify-content: center;
}
.custMain {
  padding: 20px;
  background-color: #fff;
  margin: 20px;
  border-radius: 20px;
  box-shadow: 1px 1px 10px rgb(133, 135, 139);
  overflow: hidden;
}
.medSer {
  text-align: center;
  font-size: 26px;
}
.chooseImg {
  width: 30%;
  margin: 10px 20px;
  border-radius: 30px 0 30px 0;
  border: 3px solid rgb(48, 114, 236);
}
.choose {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 100px 0;
}
.chooseImgs {
  display: flex;
  width: 100%;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
}
.chooseText {
  display: flex;
  flex: 1;
  /* border: 1px solid red; */
  flex-direction: column;
  padding: 0 60px;
  justify-content: center;
}
.docMain {
  margin: 150px 0;
  /* border: 1px solid rgb(59, 53, 53); */
  box-shadow: 2px 2px 10px rgb(97, 97, 100);
  background-color: #eef;
  padding: 20px 0 30px 0;
}
.docImage {
  width: 250px;
  height: 250px;
  border-radius: 150px;
}
.docCover {
  display: flex;
  justify-content: space-evenly;
}
.docContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.serviceImage:hover {
  scale: 1.03;
}
.misVis {
  display: flex;
  /* flex-direction: column; */
  padding: 20px 40px;
  /* width: 90%; */
}
.mission {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 20px;
}
.vision {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 20px;
}
.obj {
  display: flex;
  flex: 3;
  flex-direction: column;
  margin: 20px;
}
.misVisCont {
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeText {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 60%;
  height: 100vh;
  flex-shrink: 1;
  padding-left: 30px;
}
.logContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.logMain {
  color: red;
  /* background-color: rgb(141, 77, 245); */
  /* border: 2px solid rgb(7, 6, 6); */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 15px rgb(80, 76, 76);
  height: 60vh;
  width: 60%;
  flex-wrap: wrap;
  /* padding: 30px; */
}
.logLeft {
  display: flex;
  flex-direction: column;
  flex: 2;
  /* border: 2px solid red; */
  padding: 20px;
}
.logRight {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  background-color: rgb(141, 77, 245);
  justify-content: center;
  align-items: center;
}
.logTitleL {
  font-size: 30px;
  font-family: Akaya;
  text-align: center;
  margin-bottom: 20px;
}
.logTitleR {
  font-size: 30px;
  font-family: Akaya;
  text-align: center;
  margin-bottom: 20px;
}
.logTitleP {
  font-size: 20px;
  font-family: Akaya;
  text-align: center;
  margin-bottom: 20px;
}
.formLog {
  display: flex;
  flex-direction: column;
  height: 30vh;
  justify-content: space-evenly;
  align-items: center;
  /* flex-wrap: wrap; */
  /* width: 200px; */
}
.formReg {
  display: flex;
  flex-direction: column;
  height: 50vh;
  justify-content: space-evenly;
  align-items: center;
}
.formInpL {
  height: 35px;
  padding: 0px 15px;
  font-size: 16px;
  border-radius: 20px;
  outline: none;
  border-width: 0;
  box-shadow: 1px 1px 6px;
  width: 90%;
}
.formInp {
  height: 35px;
  padding: 0px 15px;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid rgb(15, 2, 2);
  width: 90%;
  border-radius: 20px;
  outline: none;
  margin: 3px;
}
.formSubmit {
  height: 35px;
  padding: 0px 15px;
  font-size: 16px;
  border-radius: 20px;
  outline: none;
  border-width: 0;
  box-shadow: 1px 1px 6px;
  width: 140px;
  background-color: rgb(141, 77, 245);
  color: #fff;
  margin-top: 5px;
}
.more {
  height: 20px;
  position: fixed;
  right: 50px;
  top: 12px;
}
.nomore {
  height: 20px;
  position: absolute;
  right: -20px;
  top: -22px;
}

@media screen and (max-width: 820px) {
  .headerRest {
    flex-direction: column;
    /* position: absolute;
    top: 10px;
    right: -100px; */
    align-items: flex-end;
  }
  .homeText {
    width: 80%;
  }

  .headerRest a {
    padding: 10px;
  }
  .header {
    max-height: 40px;
    min-height: 40px;
  }
  .logContainer {
    height: 120vh;
    overflow-y: scroll;
  }
}
.headerRestC {
  flex-direction: column;
  position: absolute;
  top: 10px;
  right: -100px;
}
.headerRestC a {
  padding: 10px;
}
.headerC {
  max-height: 185px;
  min-height: 190px;
}
.moreOptSty {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 50vh;
  top: 40px;
  right: -0px;
  box-shadow: 2px 2px 9px grey;
  /* background-color: rgb(241, 232, 232); */
  width: 25vw;
  align-items: center;
  justify-content: center;
}
.nomoreOptSty {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 50vh;
  top: -400px;
  right: -100px;
  box-shadow: 2px 2px 9px grey;
  /* background-color: rgb(241, 232, 232); */
  width: 25vw;
  align-items: center;
  justify-content: center;
}
.moreOptStyTop {
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 50vh;
  top: 40px;
  right: -0px;
  box-shadow: 2px 2px 9px grey;
  background-color: rgb(241, 232, 232);
  width: 30vw;
  align-items: center;
  justify-content: center;
}
.nomoreOptStyTop {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 50vh;
  top: -400px;
  right: -10px;
  box-shadow: 2px 2px 9px grey;
  background-color: rgb(241, 232, 232);
  width: 30vw;
  /* align-items: center; */
  /* justify-content: center; */
  /* justify-content: space-between;
  align-items: flex-start; */
}
.moreOptSty a {
  justify-content: space-between;
  align-items: flex-start;
  color: black;
  font-size: 22px;
  font-family: Akaya;
  margin: 30px;
}
.moreOptStyTop a {
  justify-content: space-between;
  align-items: flex-start;
  color: black;
  font-size: 22px;
  font-family: Akaya;
  margin: 10px;
}
