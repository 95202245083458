/* body {
 background-image: url("../public/bgh.jpg");
 background-repeat: no-repeat;
 background-color: #cccccc;
 height: 100vh;
} */
@font-face {
  font-family: "akaya";
  src: url("./fonts/AkayaTelivigala-Regular.ttf");
}

.bgText {
  font-family: akaya;
}
.bgText2 {
  font-family: akaya;
}
.contactIcon {
  display: flex;
  width: 40px;
  flex: 1;
}
.contactContainer {
  background: linear-gradient(90deg, #20112e, #2e61b3, #6cb9dd);
  height: 40vh;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  margin: 20px 80px;
  padding-top: 20px;
  justify-content: center;
  align-items: center;
}
.contacts {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  /* height: 150px; */
  /* overflow: hidden; */
}
.contText {
  color: #ffffff;
  font-size: 23px;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  padding-bottom: 30px;
}
.contactFirst {
  display: flex;
  /* flex: 1; */
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 120px; */
}
.contactSecond {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.contIconsSet {
  display: flex;
  width: 100%;
  /* flex: 1; */
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.contactLogo {
  width: 40px;
  height: 160px;
}
.ces {
  color: white;
  font-size: 20px;
  text-align: center;
}
.contactLogo {
  height: 90px;
  width: 90px;
  margin: -10px 0px 0px 10px;
}

a {
  font-size: 25px;
}
a:link {
  color: rgb(183, 38, 219);
}

/* visited link */
/* a:visited {
  color: rgb(16, 95, 185);
} */

/* mouse over link */
a:hover {
  color: rgb(105, 117, 158);
  opacity: 0.8;
  /* background-color: #949fd8; */
}
.anchor:hover {
  color: rgb(134, 63, 210);
  transform: scale(1.2);
  opacity: 0.5;
}
.more:hover {
  color: rgb(134, 63, 210);
  transform: scale(1.2);
  opacity: 0.5;
}
.logoHeader {
  margin: 15px 0 5px 10px;
  font-size: 24px;
}
/* selected link */
a:active {
  color: rgb(150, 18, 139);
}
@media screen and (min-width: 1500px) {
  .chooseImgSm {
    display: none;
  }
}
@media screen and (max-width: 1500px) {
  .chooseImgs {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logMain {
    height: 90vh;
  }
  .logContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  .custTitle {
    font-size: 20px;
  }
  .docRole {
    font-size: 20px;
  }
  .docImage {
    width: 200px;
    height: 200px;
  }
  .chooseImg {
    display: none;
  }
  .chooseImgSm {
    height: 500px;
    border-radius: 290px;
    padding: 5px;
    border: 4px solid #1080ce;
  }
  .chooseImgS {
    display: block;
  }
}
@media screen and (max-width: 1100px) {
  .headerRest a {
    /* display: none; */
    font-size: 18px;
  }
  .logoHeader {
    font-size: 20px;
  }
  .bgText {
    font-size: 35px;
  }
  .bgText2 {
    font-size: 25px;
  }

  /* .bgImages {
    padding-top: 20px;
    flex-direction: column-reverse;
    /* justify-content: center; */
  /* align-items: center; */
  /* height: auto; */
  /* } */
  .chooseImgs {
    display: none;
  }
  .docCover {
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 820px) {
  .more {
    top: -50px;
  }
}

@media screen and (max-width: 820px) {
  .headerRest a {
    display: none;
    /* font-size: 18px; */
  }

  .logMain {
    height: 80vh;
  }
  .logRight {
    display: none;
  }
  .logContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  .more {
    right: 50px;
  }
  .logoHeader {
    font-size: 20px;
  }
  .cover {
    flex-wrap: wrap;
  }
  .contacts {
    flex-direction: column;
  }
  hr {
    display: none;
  }
  .contIconsSet {
    flex-direction: row;
  }
  .ces {
    font-size: 14px;
  }
  .contactLogo {
    height: 50px;
    width: 50px;
  }
  .bgImages {
    flex-direction: column-reverse;
  }
}
@media only screen and (max-width: 500px) {
  .topFull {
    display: flex;
    /* flex-direction: column; */
  }
  .bgText {
    font-size: 34px;
  }
  .bgText2 {
    font-size: 23px;
  }
  .misVis {
    display: flex;
    flex-direction: column;
  }
  .serviceImageDiv {
    display: flex;
  }
  .serviceImage {
    height: 200px;
    width: 200px;
    margin-right: 5px;
  }
  .content {
    display: flex;
    /* width: 100%; */
    /* height: 70%; */
    /* justify-content: center; */
    /* align-items: center; */
    flex-direction: column;
    /* text-align: justify; */
    padding: 10px;
    margin: 10px;
    flex-shrink: 0.7;
  }
}
@media only screen and (max-width: 320px) {
  body {
    display: none;
  }
}
